<template>
  <div class="bg-white shadow sm:rounded-lg mx-4">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Filter</h3>
      <div class="mt-2 max-w-xl text-sm text-gray-500">
        <p>You can filter by number, record duration and date range.</p>
      </div>
      <form class="mt-5 sm:flex sm:items-center">
        <div class="w-full">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-4">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700"
                >Search</label
              >
              <input
                type="text"
                v-model="search"
                autocomplete="callId"
                class="
                  mt-1
                  h-9
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>

            <div class="col-span-4">
              <label
                for="last_name"
                class="block text-sm font-medium text-gray-700"
                >Duration</label
              >
              <input
                type="number"
                v-model="duration"
                autocomplete="callNumber"
                class="
                  mt-1
                  h-9
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 mt-4">
            <div class="col-span-8">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700 mb-1"
                >Date Range</label
              >
              <v-date-picker v-model="range" is-range>
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      class="
                        border
                        h-9
                        px-2
                        py-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                    <svg
                      class="w-4 h-4 mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                    <input
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      class="
                        border
                        h-9
                        px-2
                        py-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>

            <div class="col-span-2">
              <button
                :disabled="loading"
                @click="filter"
                type="button"
                class="
                  mt-6
                  h-9
                  w-full
                  inline-flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:w-auto sm:text-sm
                "
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="flex flex-col mx-4">
    <div class="overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y text-center divide-gray-200">
            <colgroup>
              <col span="1" style="width: 10%" />
              <col span="1" style="width: 15%" />
              <col span="1" style="width: 10%" />
              <col span="1" style="width: 10%" />
              <col span="1" style="width: 15%" />
              <col span="1" style="width: 30%" />
              <col span="1" style="width: 10%" />
            </colgroup>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  num
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  callerid
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  origtime
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  duration
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  mailbox
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  recording
                </th>
                <th scope="col" class="relative py-3">
                  <ClockIcon
                    v-if="loading"
                    class="animate-spin h-4. w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <VoiceMailItem
                v-for="voicemail in voicemails"
                :key="voicemail.id"
                :voicemail="voicemail"
                @remove="removeVoiceMail"
              />
            </tbody>
          </table>

          <div v-if="voicemails.length === 0" class="mx-3 mb-2">
            <EmptyTable label="You do not have any voice mail." />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <Pagination />
    </div>
  </div>

  <VoiceMailDelete
    :id="idToDelete"
    :open="deleteOpen"
    @close="deleteOpen = false"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VoiceMailDelete from "@/modules/voicemail/views/delete.vue";

import { ClockIcon } from "@heroicons/vue/solid";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import VoiceMailItem from "@/modules/voicemail/views/voicemail_item.vue";
import Pagination from "@/modules/voicemail/views/pagination.vue";
export default {
  components: {
    ClockIcon,
    EmptyTable,
    VoiceMailItem,
    Pagination,
    VoiceMailDelete,
  },
  data() {
    return {
      deleteOpen: false,
      idToDelete: 0,
      range: {
        start: new Date(),
        end: new Date(),
      },
      search: "",
      duration: "",
    };
  },
  created() {
    this.range.start.setDate(this.range.end.getDate() - 7);
    this.filter();
  },
  methods: {
    removeVoiceMail(id) {
      this.idToDelete = id;
      this.deleteOpen = true;
    },
    async filter() {
      let from = this.range?.start
        ? this.getFormattedDate(this.range.start) + " 00:00:00"
        : null;
      let to = this.range?.end
        ? this.getFormattedDate(this.range.end) + " 23:59:59"
        : null;

      this.$Progress.start();
      await this.listVoicemails({
        from: from,
        to: to,
        duration: this.duration,
        search: this.search,
      });
      this.$Progress.finish();
    },
    getFormattedDate(d) {
      return (
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2)
      );
    },
    ...mapActions("voicemail", ["listVoicemails"]),
  },
  computed: {
    ...mapGetters("voicemail", ["voicemails", "loading"]),
  },
};
</script>
