<template>
  <tr>
    <td class="py-4 whitespace-nowrap text-sm text-gray-500">
      {{ voicemail.msgnum }}
    </td>
    <td class="py-4 whitespace-nowrap">
      <div class="text-sm font-medium text-gray-900">
        {{ voicemail.callerid }}
      </div>
      <div class="text-sm text-gray-500">
        EP: {{ voicemail.endpoint?.username ?? "N/A" }}
      </div>
      <!-- <div class="flex items-center">
        <div class="w-36 overflow-x-hidden" :title="voicemail.callerid">
          <div class="text-sm font-medium text-gray-900">
            {{ voicemail.callerid }}
          </div>
          <div class="text-sm text-gray-500">
            EP: {{ voicemail.endpoint?.username ?? "N/A" }}
          </div>
        </div>
      </div>
      <h3 class="w-28 overflow-x-hidden" :title="voicemail.callerid">
        {{ voicemail.callerid }}
      </h3> -->
    </td>
    <td class="py-4 whitespace-nowrap">
      <div class="text-sm text-gray-900 max-w-xs truncate">
        {{ created }}
      </div>
    </td>
    <td class="py-2 whitespace-nowrap">
      <span
        class="
          px-2
          inline-flex
          text-xs
          leading-5
          font-semibold
          rounded-full
          bg-green-100
          text-green-800
        "
      >
        {{ voicemail.duration }}
      </span>
    </td>
    <td class="py-2 whitespace-nowrap">{{ voicemail.mailboxuser }}</td>
    <td class="py-2 w-100 min-w-full whitespace-nowrap text-sm text-gray-500">
      <audio
        controls
        class="h-10 w-full"
        download="The_title_of_te_programme.wav"
      >
        <source :src="voicemail.message_recording" type="audio/wav" />
      </audio>
    </td>
    <td class="py-4 whitespace-nowrap text-sm font-medium">
      <router-link
        title="Call"
        :to="
          `/live?phone_number=${voicemail.callerid}` +
          (voicemail.did_number ? `&did_number=${voicemail.did_number}` : '')
        "
        class="text-indigo-600 hover:text-indigo-900"
        >Call</router-link
      >
      <a
        v-if="userType === 'admin'"
        @click="remove"
        href="#"
        class="pl-4 text-indigo-600 hover:text-indigo-900"
        >Delete</a
      >
    </td>
  </tr>
</template>

<script>
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default {
  props: {
    voicemail: {
      type: Object,
      default: null,
    },
  },
  emits: ["remove"],
  methods: {
    remove() {
      this.$emit("remove", this.voicemail.id);
    },
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    created() {
      // const datetime = this.voicemail.origtime.split("T");
      // return (
      //   datetime[0] +
      //   " " +
      //   datetime[1].split(".")[0].split(":")[0] +
      //   ":" +
      //   datetime[1].split(".")[0].split(":")[1]
      // );
      const date = new Date(this.voicemail.origtime * 1000);
      return date.toLocaleString("en-US", { timeZone: "EST" });
    },
  },
};
</script>
